<template>
  <div class="content">
    <div class="top">
      <div>欢迎您的到来</div>
      <span>新用户注册</span>
    </div>
    <input type="text" v-model="phone" placeholder="输入手机号" />
    <div class="yzm-box">
      <input type="text" v-model="yzm" placeholder="输入手机验证码" />
      <span v-if="smstext" class="yzm" @click="getsms">{{smstext}}</span>
      <span class="yzm" v-else>倒计时{{time}}秒</span>
    </div>
    <input type="password" v-model="password" placeholder="设置登录密码" />
    <input type="password" v-model="conpass" placeholder="再次输入密码" />
    <van-checkbox class="box-text" v-model="checked" checked-color="#F8892F" icon-size="16px">
      <div class="text">
        我已阅读并同意
        <span>《用户协议》、《隐私协议》</span>
      </div>
    </van-checkbox>
    <div class="submit" @click="goregister">注 册</div>
    <div class="bottom">
      已有帐号，
      <span @click="gologin">立即登录</span>
    </div>
    <div>{{text}}</div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { register, sms } from '@/api/user'
export default {
  data() {
    return {
      checked: false,
      text: '',
      smstext: '获取验证码',
      time: 60,
      phone: '',
      password: '',
      conpass: '',
      yzm: ''
    }
  },
  created() {},
  methods: {
    async getsms() {
      const { data } = await sms({
        phone: this.phone
      })
      if (data.code === 200) {
        this.smstext = ''
        const interval = window.setInterval(() => {
          if (this.time-- <= 0) {
            this.time = 60
            this.smstext = '获取验证码'
            window.clearInterval(interval)
          }
        }, 1000)
      } else {
        Toast(data.msg)
      }
    },
    async goregister() {
      const phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
      if (!this.phone) {
        Toast.fail('请填写手机号')
      } else if (!phonereg.test(this.phone)) {
        Toast.fail('手机号异常')
      } else if (!this.yzm) {
        Toast.fail('请填写手机验证码')
      } else if (!this.password) {
        Toast.fail('请填写登陆密码')
      } else if (!this.conpass) {
        Toast.fail('请再次填写登陆')
      } else if (this.password !== this.conpass) {
        Toast.fail('密码填写不一致')
      } else if (!this.checked) {
        Toast.fail('请勾选用户协议')
      } else {
        const openid = localStorage.getItem('openid')
        const { data } = await register({
          confirmPassword: this.conpass,
          openid: openid,
          password: this.password,
          phone: this.phone,
          verifyCode: this.yzm
        })
        if (data.code === 200) {
          Toast.success('注册成功')
          setTimeout(() => {
            this.$router.push('/login')
          }, 2000)
        } else {
          Toast(data.msg)
        }
      }
    },
    async gologin() {
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  .box-text {
    width: 74vw;
    margin-left: 15vw;
  }
  .text {
    font-size: 10px;
    color: #c2c2c2;
    span {
      color: #333;
    }
  }
  .yzm-box {
    width: 68vw;
    margin-left: 16vw;
    height: 46px;
    position: relative;
    margin-bottom: 20px;
    input {
      margin: 0;
    }
    .yzm {
      position: absolute;
      right: 12px;
      line-height: 46px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px;
      color: #f8892f;
    }
  }
  position: relative;
  .bottom {
    text-align: center;
    font-size: 12px;
    color: #555f70;
    span {
      color: #f8892f;
    }
  }
  .submit {
    border: none;
    outline: none;
    margin-bottom: 50px;
    width: 68vw;
    margin-left: 16vw;
    height: 50px;
    border-radius: 25px;
    text-align: center;
    line-height: 50px;
    color: #fff;
    background-color: #f8892f;
    font-size: 18px;
    margin-top: 30px;
  }
  .up {
    font-size: 10px;
    color: #a6a9ba;
    width: 68vw;
    margin-left: 16vw;
    text-align: right;
    margin-top: 4px;
  }
  input {
    border: none;
    outline: none;
    width: 68vw;
    margin-left: 16vw;
    height: 44px;
    border-radius: 22px;
    background-color: #eeeff3;
    margin-bottom: 20px;
    text-indent: 24px;
  }
  input::-webkit-input-placeholder {
    font-size: 14px;
    color: #a6a9ba;
  }
  input:-ms-input-placeholder {
    font-size: 14px;
    color: #a6a9ba;
  }
  width: 100%;
  height: 100vh;
  background-color: #fbfbfb;
  padding-top: 10vh;
  box-sizing: border-box;
  .top {
    margin-bottom: 30px;
    font-size: 21px;
    color: #333950;
    text-align: center;
    div {
      font-weight: bold;
    }
    span {
      font-size: 12px;
      color: #a0a5bb;
    }
  }
}
</style>
